// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-LogsLayout-module__buttonRow-CsF6r {
  display: flex;
  gap: 24px;
}
@media (max-width: 768px) {
  .nightbot-LogsLayout-module__buttonRow-CsF6r {
    flex-direction: column;
}
  }`, "",{"version":3,"sources":["webpack://./src/components/layouts/LogsLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AAKX;AAHE;EAJF;IAKI,sBAAsB;AAE1B;EADE","sourcesContent":[".buttonRow {\n  display: flex;\n  gap: 24px;\n\n  @media (max-width: $mantine-breakpoint-sm) {\n    flex-direction: column;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonRow": `nightbot-LogsLayout-module__buttonRow-CsF6r`
};
export default ___CSS_LOADER_EXPORT___;
