function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _instanceof(left, right) {
    if (right != null && typeof Symbol !== "undefined" && right[Symbol.hasInstance]) {
        return !!right[Symbol.hasInstance](left);
    } else {
        return left instanceof right;
    }
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { GiveawayType } from '@/types/client';
import { UserLevels } from '@/types/server';
import { StorageKeys } from '@/utils/constants';
import { serializeProviderUserId } from '@/utils/user';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
var useGiveawayStore = create()(persist(function(set) {
    var _obj;
    return {
        winners: new Set(),
        banned: new Set(),
        type: GiveawayType.ACTIVE_USER,
        phrase: '',
        maxRandomNumber: 100,
        activityTimeout: 10,
        minRandomNumber: 1,
        users: new Map(),
        shouldSendWinnerMessage: true,
        eligibility: [
            UserLevels.EVERYONE,
            UserLevels.SUBSCRIBER,
            UserLevels.REGULAR,
            UserLevels.MODERATOR,
            UserLevels.TWITCH_VIP
        ],
        preventPhraseSpam: false,
        uniqueWinners: false,
        luck: (_obj = {}, _define_property(_obj, UserLevels.EVERYONE, 1), _define_property(_obj, UserLevels.SUBSCRIBER, 1), _define_property(_obj, UserLevels.REGULAR, 1), _define_property(_obj, UserLevels.MODERATOR, 1), _define_property(_obj, UserLevels.TWITCH_VIP, 1), _obj),
        setType: function(type) {
            return set({
                type: type
            });
        },
        setUsers: function(users) {
            return _instanceof(users, Map) ? set({
                users: users
            }) : set(function(state) {
                return {
                    users: users(state.users)
                };
            });
        },
        setUser: function(user) {
            var giveawayUserId = serializeProviderUserId(user);
            set(function(state) {
                var newMap = new Map(state.users);
                newMap.set(giveawayUserId, user);
                return {
                    users: newMap
                };
            });
            return user;
        },
        setLuck: function(userLevel, luck) {
            return set(function(state) {
                return {
                    luck: _object_spread_props(_object_spread({}, state.luck), _define_property({}, userLevel, luck))
                };
            });
        },
        setEligibility: function(eligibility) {
            return set({
                eligibility: eligibility
            });
        },
        setPhrase: function(phrase) {
            return set({
                phrase: phrase
            });
        },
        setMaxRandomNumber: function(maxRandomNumber) {
            return set({
                maxRandomNumber: maxRandomNumber
            });
        },
        setMinRandomNumber: function(minRandomNumber) {
            return set({
                minRandomNumber: minRandomNumber
            });
        },
        setActivityTimeout: function(activityTimeout) {
            return set({
                activityTimeout: activityTimeout
            });
        },
        setPreventPhraseSpam: function(preventPhraseSpam) {
            return set({
                preventPhraseSpam: preventPhraseSpam
            });
        },
        setUniqueWinners: function(uniqueWinners) {
            return set({
                uniqueWinners: uniqueWinners
            });
        },
        setShouldSendWinnerMessage: function(shouldSendWinnerMessage) {
            return set({
                shouldSendWinnerMessage: shouldSendWinnerMessage
            });
        },
        addGiveawayWinner: function(serializeProviderUserId) {
            return set(function(state) {
                return {
                    winners: new Set(state.winners).add(serializeProviderUserId)
                };
            });
        }
    };
}, {
    name: StorageKeys.GIVEAWAY_STORE,
    partialize: function(param) {
        var type = param.type, luck = param.luck, minRandomNumber = param.minRandomNumber, maxRandomNumber = param.maxRandomNumber, eligibility = param.eligibility, phrase = param.phrase, activityTimeout = param.activityTimeout, preventPhraseSpam = param.preventPhraseSpam, uniqueWinners = param.uniqueWinners, shouldSendWinnerMessage = param.shouldSendWinnerMessage;
        return {
            type: type,
            luck: luck,
            minRandomNumber: minRandomNumber,
            maxRandomNumber: maxRandomNumber,
            eligibility: eligibility,
            phrase: phrase,
            activityTimeout: activityTimeout,
            preventPhraseSpam: preventPhraseSpam,
            uniqueWinners: uniqueWinners,
            shouldSendWinnerMessage: shouldSendWinnerMessage
        };
    }
}));
export default useGiveawayStore;
