// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-MessageLogs-module__messageColumn-dSCgN {
  max-width: 1px;
  white-space: normal;
  overflow: hidden;
}

.nightbot-MessageLogs-module__createdAtColumn-MHI4H {
  white-space: nowrap;
  width: 1px;
  vertical-align: top;
}

.nightbot-MessageLogs-module__userColumn-Ub6ih {
  white-space: nowrap;
  width: 1px;
  vertical-align: top;
  max-width: 112px;
}

.nightbot-MessageLogs-module__messageStack-UpkDi {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.nightbot-MessageLogs-module__messageStack-UpkDi .nightbot-MessageLogs-module__userName-japjh {
    flex: 1;
  }

.nightbot-MessageLogs-module__messageStack-UpkDi .nightbot-MessageLogs-module__userTimestamp-aQL2R {
    color: var(--text-tertiary);
    --text-color: var(--text-tertiary);
  }

.nightbot-MessageLogs-module__messageStack-UpkDi .nightbot-MessageLogs-module__userMessage-XgJLd {
    overflow: hidden;
    white-space: normal;
    width: 100%;
    color: var(--text-secondary);
    --text-color: var(--text-secondary);
  }`, "",{"version":3,"sources":["webpack://./src/components/pages/MessageLogs.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,UAAU;EACV,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,SAAS;AAkBX;;AAhBE;IACE,OAAO;EACT;;AAEA;IACE,2BAA2B;IAC3B,kCAAkC;EACpC;;AAEA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;IACX,4BAA4B;IAC5B,mCAAmC;EACrC","sourcesContent":[".messageColumn {\n  max-width: 1px;\n  white-space: normal;\n  overflow: hidden;\n}\n\n.createdAtColumn {\n  white-space: nowrap;\n  width: 1px;\n  vertical-align: top;\n}\n\n.userColumn {\n  white-space: nowrap;\n  width: 1px;\n  vertical-align: top;\n  max-width: 112px;\n}\n\n.messageStack {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  gap: 12px;\n\n  .userName {\n    flex: 1;\n  }\n\n  .userTimestamp {\n    color: var(--text-tertiary);\n    --text-color: var(--text-tertiary);\n  }\n\n  .userMessage {\n    overflow: hidden;\n    white-space: normal;\n    width: 100%;\n    color: var(--text-secondary);\n    --text-color: var(--text-secondary);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageColumn": `nightbot-MessageLogs-module__messageColumn-dSCgN`,
	"createdAtColumn": `nightbot-MessageLogs-module__createdAtColumn-MHI4H`,
	"userColumn": `nightbot-MessageLogs-module__userColumn-Ub6ih`,
	"messageStack": `nightbot-MessageLogs-module__messageStack-UpkDi`,
	"userName": `nightbot-MessageLogs-module__userName-japjh`,
	"userTimestamp": `nightbot-MessageLogs-module__userTimestamp-aQL2R`,
	"userMessage": `nightbot-MessageLogs-module__userMessage-XgJLd`
};
export default ___CSS_LOADER_EXPORT___;
