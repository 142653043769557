// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-GiveawayUserEntryList-module__container-dd318 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.nightbot-GiveawayUserEntryList-module__header-orjaI {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  background-color: var(--background-primary);
}

.nightbot-GiveawayUserEntryList-module__entryCount-AUzP_ {
  color: var(--text-secondary);
  --text-color: var(--text-secondary);
}

.nightbot-GiveawayUserEntryList-module__entry-kUwy_ {
  display: flex;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid var(--border-primary);
  align-items: center;
  background-color: var(--background-primary);
}

.nightbot-GiveawayUserEntryList-module__entryTextDisabled-vnQF8 {
  -webkit-text-decoration: line-through !important;
  text-decoration: line-through !important;
  color: var(--text-tertiary);
  --text-color: var(--text-tertiary);
}

.nightbot-GiveawayUserEntryList-module__checkbox-ApIG7 {
  margin-left: auto;
}

.nightbot-GiveawayUserEntryList-module__entries-eJ_Ka {
  flex: 1;
  overflow-y: hidden;
}

.nightbot-GiveawayUserEntryList-module__emptyState-EjRws {
  flex-grow: 1;
  display: grid;
  align-items: center;
  justify-items: center;
  place-items: center;
  color: var(--text-secondary);
}

.nightbot-GiveawayUserEntryList-module__footer-movMd {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
  align-items: center;
  background-color: var(--background-primary);
}`, "",{"version":3,"sources":["webpack://./src/components/giveaways/GiveawayUserEntryList.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,OAAO;AACT;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,2CAA2C;AAC7C;;AAEA;EACE,4BAA4B;EAC5B,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,SAAS;EACT,aAAa;EACb,8CAA8C;EAC9C,mBAAmB;EACnB,2CAA2C;AAC7C;;AAEA;EACE,gDAAwC;EAAxC,wCAAwC;EACxC,2BAA2B;EAC3B,kCAAkC;AACpC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,OAAO;EACP,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EAAnB,qBAAmB;EAAnB,mBAAmB;EACnB,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,2CAA2C;AAC7C","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  padding: 16px;\n  align-items: center;\n  background-color: var(--background-primary);\n}\n\n.entryCount {\n  color: var(--text-secondary);\n  --text-color: var(--text-secondary);\n}\n\n.entry {\n  display: flex;\n  gap: 16px;\n  padding: 16px;\n  border-bottom: 1px solid var(--border-primary);\n  align-items: center;\n  background-color: var(--background-primary);\n}\n\n.entryTextDisabled {\n  text-decoration: line-through !important;\n  color: var(--text-tertiary);\n  --text-color: var(--text-tertiary);\n}\n\n.checkbox {\n  margin-left: auto;\n}\n\n.entries {\n  flex: 1;\n  overflow-y: hidden;\n}\n\n.emptyState {\n  flex-grow: 1;\n  display: grid;\n  place-items: center;\n  color: var(--text-secondary);\n}\n\n.footer {\n  display: flex;\n  justify-content: space-between;\n  padding: 16px;\n  gap: 16px;\n  align-items: center;\n  background-color: var(--background-primary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `nightbot-GiveawayUserEntryList-module__container-dd318`,
	"header": `nightbot-GiveawayUserEntryList-module__header-orjaI`,
	"entryCount": `nightbot-GiveawayUserEntryList-module__entryCount-AUzP_`,
	"entry": `nightbot-GiveawayUserEntryList-module__entry-kUwy_`,
	"entryTextDisabled": `nightbot-GiveawayUserEntryList-module__entryTextDisabled-vnQF8`,
	"checkbox": `nightbot-GiveawayUserEntryList-module__checkbox-ApIG7`,
	"entries": `nightbot-GiveawayUserEntryList-module__entries-eJ_Ka`,
	"emptyState": `nightbot-GiveawayUserEntryList-module__emptyState-EjRws`,
	"footer": `nightbot-GiveawayUserEntryList-module__footer-movMd`
};
export default ___CSS_LOADER_EXPORT___;
