// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-Table-module__panel-b0aPY {
  overflow: visible;
  overflow: initial;
  contain: paint;
  padding: 0;
}

.nightbot-Table-module__table-jqVVC {
  max-width: 100%;
  border-collapse: collapse;
}

.nightbot-Table-module__table-jqVVC tr {
    border-color: var(--border-primary);
  }

.nightbot-Table-module__table-jqVVC td {
    padding: 16px;
  }

.nightbot-Table-module__table-jqVVC th {
    border-width: 0px;
    white-space: nowrap;
    padding: 12px 16px;
  }

.nightbot-Table-module__table-jqVVC thead {
    position: sticky;
    top: 0;
    inset-block-start: 0;
  }

.nightbot-Table-module__table-jqVVC tfoot td {
    -moz-column-span: all;
         column-span: all;
  }

.nightbot-Table-module__table-jqVVC .nightbot-Table-module__tableHeader-CGUXN {
    position: sticky;
    top: -1px;
    z-index: 1;
    background-color: var(--background-primary);
    color: var(--text-secondary);
    border-color: var(--border-primary);
  }

.nightbot-Table-module__table-jqVVC .nightbot-Table-module__tableHeader-CGUXN::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: var(--border-primary);
    }

.nightbot-Table-module__footer-Fq2oj {
  position: sticky;
  bottom: 0;
  border-color: var(--border-primary);
  background-color: var(--background-primary);
  border-top-width: 1px;
}

.nightbot-Table-module__emptyStateContainer-v3djG {
  display: grid;
  align-items: center;
  justify-items: center;
  place-items: center;
  overflow: hidden;
  border-style: dashed;
  border-color: var(--border-primary);
  padding: 12px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Table.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAe;EAAf,iBAAe;EACf,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,yBAAyB;AA4C3B;;AA1CE;IACE,mCAAmC;EACrC;;AAEA;IACE,aAAa;EACf;;AAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;EACpB;;AAEA;IACE,gBAAgB;IAChB,MAAM;IACN,oBAAoB;EACtB;;AAEA;IACE,qBAAgB;SAAhB,gBAAgB;EAClB;;AAEA;IACE,gBAAgB;IAChB,SAAS;IACT,UAAU;IACV,2CAA2C;IAC3C,4BAA4B;IAC5B,mCAAmC;EAWrC;;AATE;MACE,WAAW;MACX,kBAAkB;MAClB,SAAS;MACT,OAAO;MACP,QAAQ;MACR,WAAW;MACX,uCAAuC;IACzC;;AAIJ;EACE,gBAAgB;EAChB,SAAS;EACT,mCAAmC;EACnC,2CAA2C;EAC3C,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;EAAnB,qBAAmB;EAAnB,mBAAmB;EACnB,gBAAgB;EAChB,oBAAoB;EACpB,mCAAmC;EACnC,iBAAiB;AACnB","sourcesContent":[".panel {\n  overflow: unset;\n  contain: paint;\n  padding: 0;\n}\n\n.table {\n  max-width: 100%;\n  border-collapse: collapse;\n\n  tr {\n    border-color: var(--border-primary);\n  }\n\n  td {\n    padding: 16px;\n  }\n\n  th {\n    border-width: 0px;\n    white-space: nowrap;\n    padding: 12px 16px;\n  }\n\n  thead {\n    position: sticky;\n    top: 0;\n    inset-block-start: 0;\n  }\n\n  tfoot td {\n    column-span: all;\n  }\n\n  .tableHeader {\n    position: sticky;\n    top: -1px;\n    z-index: 1;\n    background-color: var(--background-primary);\n    color: var(--text-secondary);\n    border-color: var(--border-primary);\n\n    &::after {\n      content: '';\n      position: absolute;\n      bottom: 0;\n      left: 0;\n      right: 0;\n      height: 1px;\n      background-color: var(--border-primary);\n    }\n  }\n}\n\n.footer {\n  position: sticky;\n  bottom: 0;\n  border-color: var(--border-primary);\n  background-color: var(--background-primary);\n  border-top-width: 1px;\n}\n\n.emptyStateContainer {\n  display: grid;\n  place-items: center;\n  overflow: hidden;\n  border-style: dashed;\n  border-color: var(--border-primary);\n  padding: 12px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `nightbot-Table-module__panel-b0aPY`,
	"table": `nightbot-Table-module__table-jqVVC`,
	"tableHeader": `nightbot-Table-module__tableHeader-CGUXN`,
	"footer": `nightbot-Table-module__footer-Fq2oj`,
	"emptyStateContainer": `nightbot-Table-module__emptyStateContainer-v3djG`
};
export default ___CSS_LOADER_EXPORT___;
