// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-Timers-module__timerNameColumn-kl2Qm {
  max-width: 1px;
}

.nightbot-Timers-module__timerNameColumn-kl2Qm .nightbot-Timers-module__timerEnabledBadge-ExWCo {
    overflow: visible;
  }

.nightbot-Timers-module__timerNameColumn-kl2Qm .nightbot-Timers-module__timerMessage-JcpBH {
    width: 100%;
    word-break: break-all;
  }

.nightbot-Timers-module__timerNameColumn-kl2Qm .nightbot-Timers-module__timerNameStack-KT78i {
    display: flex;
    max-width: 100%;
    gap: 8px;
  }

.nightbot-Timers-module__timerNameColumn-kl2Qm .nightbot-Timers-module__timerNameStack-KT78i .nightbot-Timers-module__timerName-ufpBs {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

.nightbot-Timers-module__timerNameColumn-kl2Qm .nightbot-Timers-module__timerMessage-JcpBH {
    color: var(--text-secondary);
  }

.nightbot-Timers-module__timerIntervalHeader-_rO_3 {
  width: 1px;
}

.nightbot-Timers-module__timerIntervalColumn-M5XUm {
  text-align: right;
  width: 1px;
  white-space: nowrap;
}

.nightbot-Timers-module__timerEnabledColumn-c3Vtg {
  text-align: center;
  width: 1px;
}

.nightbot-Timers-module__actionIcons-gcO6S {
  display: flex;
  gap: 8px;
  justify-content: right;
}

.nightbot-Timers-module__timerEnabledSwitchContainer-vftlM {
  display: flex;
  justify-content: center;
}

.nightbot-Timers-module__timerActionsColumn-j8qpG {
  width: 1px;
}

.nightbot-Timers-module__timerEnabledHeader-sbNfw {
  width: 1px;
}

.nightbot-Timers-module__buttonRow-iF1O3 {
  display: flex;
  gap: 24px;
}

.nightbot-Timers-module__newTimerButton-L57Bq {
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Timers.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;AA0BhB;;AAxBE;IACE,iBAAiB;EACnB;;AAEA;IACE,WAAW;IACX,qBAAqB;EACvB;;AAEA;IACE,aAAa;IACb,eAAe;IACf,QAAQ;EAOV;;AALE;MACE,gBAAgB;MAChB,uBAAuB;MACvB,mBAAmB;IACrB;;AAGF;IACE,4BAA4B;EAC9B;;AAGF;EACE,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".timerNameColumn {\n  max-width: 1px;\n\n  .timerEnabledBadge {\n    overflow: visible;\n  }\n\n  .timerMessage {\n    width: 100%;\n    word-break: break-all;\n  }\n\n  .timerNameStack {\n    display: flex;\n    max-width: 100%;\n    gap: 8px;\n\n    .timerName {\n      overflow: hidden;\n      text-overflow: ellipsis;\n      white-space: nowrap;\n    }\n  }\n\n  .timerMessage {\n    color: var(--text-secondary);\n  }\n}\n\n.timerIntervalHeader {\n  width: 1px;\n}\n\n.timerIntervalColumn {\n  text-align: right;\n  width: 1px;\n  white-space: nowrap;\n}\n\n.timerEnabledColumn {\n  text-align: center;\n  width: 1px;\n}\n\n.actionIcons {\n  display: flex;\n  gap: 8px;\n  justify-content: right;\n}\n\n.timerEnabledSwitchContainer {\n  display: flex;\n  justify-content: center;\n}\n\n.timerActionsColumn {\n  width: 1px;\n}\n\n.timerEnabledHeader {\n  width: 1px;\n}\n\n.buttonRow {\n  display: flex;\n  gap: 24px;\n}\n\n.newTimerButton {\n  margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timerNameColumn": `nightbot-Timers-module__timerNameColumn-kl2Qm`,
	"timerEnabledBadge": `nightbot-Timers-module__timerEnabledBadge-ExWCo`,
	"timerMessage": `nightbot-Timers-module__timerMessage-JcpBH`,
	"timerNameStack": `nightbot-Timers-module__timerNameStack-KT78i`,
	"timerName": `nightbot-Timers-module__timerName-ufpBs`,
	"timerIntervalHeader": `nightbot-Timers-module__timerIntervalHeader-_rO_3`,
	"timerIntervalColumn": `nightbot-Timers-module__timerIntervalColumn-M5XUm`,
	"timerEnabledColumn": `nightbot-Timers-module__timerEnabledColumn-c3Vtg`,
	"actionIcons": `nightbot-Timers-module__actionIcons-gcO6S`,
	"timerEnabledSwitchContainer": `nightbot-Timers-module__timerEnabledSwitchContainer-vftlM`,
	"timerActionsColumn": `nightbot-Timers-module__timerActionsColumn-j8qpG`,
	"timerEnabledHeader": `nightbot-Timers-module__timerEnabledHeader-sbNfw`,
	"buttonRow": `nightbot-Timers-module__buttonRow-iF1O3`,
	"newTimerButton": `nightbot-Timers-module__newTimerButton-L57Bq`
};
export default ___CSS_LOADER_EXPORT___;
