// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-DiscordIntegration-module__footer-ayTbn {
  color: var(--text-secondary);
  --text-color: var(--text-secondary);
}

.nightbot-DiscordIntegration-module__header-PyHqT {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 16px;
}

.nightbot-DiscordIntegration-module__serverSelect-UDNS0 {
  max-width: 200px;
}

.nightbot-DiscordIntegration-module__partButton-I95Hy {
  color: var(--color-negative);
  --button-color: var(--color-negative);
}`, "",{"version":3,"sources":["webpack://./src/components/integrations/DiscordIntegration.module.scss"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;EACrB,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;EAC5B,qCAAqC;AACvC","sourcesContent":[".footer {\n  color: var(--text-secondary);\n  --text-color: var(--text-secondary);\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n  gap: 16px;\n}\n\n.serverSelect {\n  max-width: 200px;\n}\n\n.partButton {\n  color: var(--color-negative);\n  --button-color: var(--color-negative);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `nightbot-DiscordIntegration-module__footer-ayTbn`,
	"header": `nightbot-DiscordIntegration-module__header-PyHqT`,
	"serverSelect": `nightbot-DiscordIntegration-module__serverSelect-UDNS0`,
	"partButton": `nightbot-DiscordIntegration-module__partButton-I95Hy`
};
export default ___CSS_LOADER_EXPORT___;
