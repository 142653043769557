// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-GiveawayRandomNumberStep-module__afterElementContainer-Y9bwG {
  width: 100%;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  flex-direction: row;
}

.nightbot-GiveawayRandomNumberStep-module__numberInput-vLzuJ {
  flex: 1;
}

.nightbot-GiveawayRandomNumberStep-module__subHeading-cWFNS {
  color: var(--text-secondary);
}

.nightbot-GiveawayRandomNumberStep-module__settingsPanel-iQlrN {
  background-color: var(--background-hover);
  border-color: var(--border-secondary);
}`, "",{"version":3,"sources":["webpack://./src/components/giveaways/GiveawayRandomNumberStep.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,SAAS;EACT,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,yCAAyC;EACzC,qCAAqC;AACvC","sourcesContent":[".afterElementContainer {\n  width: 100%;\n  display: flex;\n  gap: 16px;\n  flex-wrap: wrap;\n  flex-direction: row;\n}\n\n.numberInput {\n  flex: 1;\n}\n\n.subHeading {\n  color: var(--text-secondary);\n}\n\n.settingsPanel {\n  background-color: var(--background-hover);\n  border-color: var(--border-secondary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"afterElementContainer": `nightbot-GiveawayRandomNumberStep-module__afterElementContainer-Y9bwG`,
	"numberInput": `nightbot-GiveawayRandomNumberStep-module__numberInput-vLzuJ`,
	"subHeading": `nightbot-GiveawayRandomNumberStep-module__subHeading-cWFNS`,
	"settingsPanel": `nightbot-GiveawayRandomNumberStep-module__settingsPanel-iQlrN`
};
export default ___CSS_LOADER_EXPORT___;
