// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-CommandsCustomCreate-module__buttonGroup-m_xMr {
  display: flex;
  justify-content: space-between;
}

.nightbot-CommandsCustomCreate-module__error-ZxaHR {
  justify-content: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/CommandsCustomCreate.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".buttonGroup {\n  display: flex;\n  justify-content: space-between;\n}\n\n.error {\n  justify-content: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonGroup": `nightbot-CommandsCustomCreate-module__buttonGroup-m_xMr`,
	"error": `nightbot-CommandsCustomCreate-module__error-ZxaHR`
};
export default ___CSS_LOADER_EXPORT___;
