// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-GiveawayCreateFooter-module__footer-b2XLU {
  display: flex;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--background-primary);
  z-index: 2;
  margin-top: auto;
  padding: 24px 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/giveaways/GiveawayCreateFooter.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,2CAA2C;EAC3C,UAAU;EACV,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".footer {\n  display: flex;\n  justify-content: space-between;\n  position: sticky;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-color: var(--background-primary);\n  z-index: 2;\n  margin-top: auto;\n  padding: 24px 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `nightbot-GiveawayCreateFooter-module__footer-b2XLU`
};
export default ___CSS_LOADER_EXPORT___;
