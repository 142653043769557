// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-SpamProtectionFilter-module__filterPanel-ckbEw {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.nightbot-SpamProtectionFilter-module__filterPanelFooter-B3DHN, .nightbot-SpamProtectionFilter-module__filterPanelHeader-j0ywv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nightbot-SpamProtectionFilter-module__filterPanelFooterText-SJUDU, .nightbot-SpamProtectionFilter-module__learnMore-nIUXh {
  color: var(--text-secondary);
  --text-color: var(--text-secondary);
}

`, "",{"version":3,"sources":["webpack://./src/components/pages/SpamProtectionFilter.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,4BAA4B;EAC5B,mCAAmC;AACrC","sourcesContent":[".filterPanel {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding: 16px;\n}\n\n.filterPanelFooter, .filterPanelHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.filterPanelFooterText, .learnMore {\n  color: var(--text-secondary);\n  --text-color: var(--text-secondary);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterPanel": `nightbot-SpamProtectionFilter-module__filterPanel-ckbEw`,
	"filterPanelFooter": `nightbot-SpamProtectionFilter-module__filterPanelFooter-B3DHN`,
	"filterPanelHeader": `nightbot-SpamProtectionFilter-module__filterPanelHeader-j0ywv`,
	"filterPanelFooterText": `nightbot-SpamProtectionFilter-module__filterPanelFooterText-SJUDU`,
	"learnMore": `nightbot-SpamProtectionFilter-module__learnMore-nIUXh`
};
export default ___CSS_LOADER_EXPORT___;
