// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-GiveawayLuckSettings-module__numberInput-qqq70 {
  width: -moz-fit-content;
  width: fit-content;
}

.nightbot-GiveawayLuckSettings-module__settingsPanel-P3Hok {
  background-color: var(--background-hover);
  border-color: var(--border-secondary);
}`, "",{"version":3,"sources":["webpack://./src/components/giveaways/GiveawayLuckSettings.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,yCAAyC;EACzC,qCAAqC;AACvC","sourcesContent":[".numberInput {\n  width: fit-content;\n}\n\n.settingsPanel {\n  background-color: var(--background-hover);\n  border-color: var(--border-secondary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"numberInput": `nightbot-GiveawayLuckSettings-module__numberInput-qqq70`,
	"settingsPanel": `nightbot-GiveawayLuckSettings-module__settingsPanel-P3Hok`
};
export default ___CSS_LOADER_EXPORT___;
