// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-segmentedControl-module__segmentedControl-DMZU_ {
  padding: 1px;
  border-radius: var(--mantine-radius-md);
  border: 1px solid var(--border-primary);
  background-color: var(--background-primary);
}

[data-mantine-color-scheme='light']:root .nightbot-segmentedControl-module__segmentedControl-DMZU_ {
    border-color: var(--border-secondary);
  }

.nightbot-segmentedControl-module__indicator-jgmmL {
  background-color: var(--background-inverse);
}

.nightbot-segmentedControl-module__label-HrdLT {
  height: 32px;
  color: var(--text-tertiary);
}

.nightbot-segmentedControl-module__label-HrdLT[data-active] {
    color: var(--text-inverse);
    --sc-label-color: var(--text-inverse);
  }`, "",{"version":3,"sources":["webpack://./src/utils/mantine-theme/segmentedControl.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,uCAAuC;EACvC,uCAAuC;EACvC,2CAA2C;AAC7C;;AAGE;IACE,qCAAqC;EACvC;;AAGF;EACE,2CAA2C;AAC7C;;AAEA;EACE,YAAY;EACZ,2BAA2B;AAM7B;;AAJE;IACE,0BAA0B;IAC1B,qCAAqC;EACvC","sourcesContent":[".segmentedControl {\n  padding: 1px;\n  border-radius: var(--mantine-radius-md);\n  border: 1px solid var(--border-primary);\n  background-color: var(--background-primary);\n}\n\n@mixin light-root  {\n  .segmentedControl {\n    border-color: var(--border-secondary);\n  }\n}\n\n.indicator {\n  background-color: var(--background-inverse);\n}\n\n.label {\n  height: 32px;\n  color: var(--text-tertiary);\n\n  &[data-active] {\n    color: var(--text-inverse);\n    --sc-label-color: var(--text-inverse);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"segmentedControl": `nightbot-segmentedControl-module__segmentedControl-DMZU_`,
	"indicator": `nightbot-segmentedControl-module__indicator-jgmmL`,
	"label": `nightbot-segmentedControl-module__label-HrdLT`
};
export default ___CSS_LOADER_EXPORT___;
