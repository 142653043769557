import { createTheme, Input, InputWrapper, Button, Textarea, Menu, Divider, Badge, ActionIcon, Switch, Avatar, Loader, NativeSelect, Modal, Tabs, List, SegmentedControl } from '@mantine/core';
import '@fontsource/lexend';
import '@mantine/core/styles.css';
import './variables.scss';
import buttonStyles from './button.module.scss';
import inputStyles from './input.module.scss';
import menuStyles from './menu.module.scss';
import dividerStyles from './divider.module.scss';
import badgeStyles from './badge.module.scss';
import avatarStyles from './avatar.module.scss';
import selectStyles from './select.module.scss';
import modalStyles from './modal.module.scss';
import tabsStyles from './tabs.module.scss';
import segmentedControlStyles from './segmentedControl.module.scss';
export default createTheme({
    fontFamily: "'Lexend', sans-serif",
    cursorType: 'pointer',
    headings: {
        sizes: {
            h1: {
                fontSize: '24px'
            },
            h2: {
                fontSize: '20px'
            },
            h3: {
                fontSize: '16px'
            },
            h4: {
                fontSize: '12px'
            },
            h5: {
                fontSize: '8px'
            }
        }
    },
    spacing: {
        xs: '4px',
        sm: '8px',
        md: '12px',
        lg: '16px',
        xl: '24px'
    },
    shadows: {
        sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
        md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
        lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
        xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
        outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
        xs: '0 0.5px 0.5px 0 rgba(0, 0, 0, 0.2)'
    },
    radius: {
        xs: '2px',
        sm: '4px',
        md: '6px',
        lg: '8px',
        xl: '12px',
        full: '9999px'
    },
    fontSizes: {
        xs: '12px',
        sm: '14px',
        md: '16px',
        lg: '18px',
        xl: '20px'
    },
    colors: {
        gray: [
            '#f9fafa',
            '#f1f1f2',
            '#f6f6f6',
            '#e7e7e8',
            '#d3d4d5',
            '#acadae',
            '#7e7f82',
            '#535458',
            '#35373b',
            '#26282d',
            '#1e2025',
            '#18191e'
        ],
        purple: [
            '#f8f5ff',
            '#e5d9fe',
            '#d1bdfd',
            '#b593fc',
            '#a075fb',
            '#8157db',
            '#6c49b7',
            '#583c96',
            '#48317b',
            '#35245b'
        ],
        pink: [
            '#fff5fa',
            '#fed6eb',
            '#fdb4da',
            '#fc81c0',
            '#f160ab',
            '#c74f8d',
            '#aa4479',
            '#8a3762',
            '#6c2b4c',
            '#4f2038'
        ],
        orange: [
            '#fffaf5',
            '#feead7',
            '#fdd1a7',
            '#f8ab63',
            '#d69455',
            '#b97f4a',
            '#9c6b3e',
            '#7c5531',
            '#624327',
            '#503720'
        ],
        yellow: [
            '#fffefa',
            '#fef9e2',
            '#fdeda9',
            '#fade64',
            '#e5cb5b',
            '#bca74b',
            '#97863c',
            '#75682f',
            '#584e23',
            '#48401d'
        ],
        green: [
            '#f0fff7',
            '#a6fdd0',
            '#5fefa5',
            '#55d593',
            '#4aba80',
            '#40a06e',
            '#35845b',
            '#296747',
            '#22543a',
            '#1c4530'
        ],
        teal: [
            '#e7fefe',
            '#7efcf9',
            '#5de9e6',
            '#53d0cd',
            '#46b1ae',
            '#3c9694',
            '#317a78',
            '#265f5d',
            '#1f4f4e',
            '#1a4140'
        ],
        cyan: [
            '#effcff',
            '#b9f3fd',
            '#96edfc',
            '#6ae5fb',
            '#56c3d7',
            '#4fb3c5',
            '#47a1b2',
            '#3b8593',
            '#306e79',
            '#25555d'
        ],
        blue: [
            '#f4f7ff',
            '#d2e1fe',
            '#aac6fd',
            '#79a5fc',
            '#6192f3',
            '#527bcd',
            '#4568ad',
            '#37548b',
            '#2f4775',
            '#223355'
        ]
    },
    primaryShade: {
        light: 5,
        dark: 6
    },
    primaryColor: 'blue',
    components: {
        Input: Input.extend({
            classNames: {
                input: inputStyles.input
            }
        }),
        InputWrapper: InputWrapper.extend({
            classNames: {
                root: inputStyles.inputWrapperRoot,
                // https://github.com/atfzl/eslint-plugin-css-modules/issues/103
                // eslint-disable-next-line css-modules/no-undef-class
                description: inputStyles.inputWrapperDescription,
                // https://github.com/atfzl/eslint-plugin-css-modules/issues/103
                // eslint-disable-next-line css-modules/no-undef-class
                label: inputStyles.inputWrapperLabel
            }
        }),
        Button: Button.extend({
            defaultProps: {
                variant: 'normal'
            },
            classNames: {
                root: buttonStyles.button,
                inner: buttonStyles.buttonInner,
                loader: buttonStyles.buttonLoader
            }
        }),
        Badge: Badge.extend({
            defaultProps: {
                radius: 'sm',
                variant: 'light',
                color: 'dark'
            },
            classNames: {
                root: badgeStyles.badge,
                label: badgeStyles.label
            }
        }),
        ActionIcon: ActionIcon.extend({
            defaultProps: {
                variant: 'normal'
            },
            classNames: {
                root: buttonStyles.button
            }
        }),
        Textarea: Textarea.extend({
            classNames: {
                input: inputStyles.textarea
            }
        }),
        Menu: Menu.extend({
            classNames: {
                dropdown: menuStyles.dropdown,
                item: menuStyles.item,
                label: menuStyles.label,
                divider: dividerStyles.divider
            },
            defaultProps: {
                shadow: 'md',
                withArrow: true
            }
        }),
        Divider: Divider.extend({
            classNames: {
                root: dividerStyles.divider
            }
        }),
        Switch: Switch.extend({
            classNames: {
                root: inputStyles.switch,
                track: inputStyles.switchTrack,
                thumb: inputStyles.switchThumb
            }
        }),
        Avatar: Avatar.extend({
            classNames: {
                root: avatarStyles.avatar
            }
        }),
        Loader: Loader.extend({
            defaultProps: {
                size: 'sm',
                color: 'gray'
            }
        }),
        NativeSelect: NativeSelect.extend({
            classNames: {
                root: selectStyles.select,
                input: selectStyles.input
            }
        }),
        Modal: Modal.extend({
            classNames: {
                header: modalStyles.header,
                body: modalStyles.body,
                content: modalStyles.content
            }
        }),
        Tabs: Tabs.extend({
            classNames: {
                tab: tabsStyles.tab,
                list: tabsStyles.list
            }
        }),
        List: List.extend({
            defaultProps: {
                icon: '•'
            }
        }),
        SegmentedControl: SegmentedControl.extend({
            classNames: {
                root: segmentedControlStyles.segmentedControl,
                indicator: segmentedControlStyles.indicator,
                label: segmentedControlStyles.label
            }
        })
    }
});
