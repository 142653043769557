// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-PrivacyPolicy-module__container-DskCJ {
  background-color: var(--background-secondary);
}
.nightbot-PrivacyPolicy-module__container-DskCJ ul {
    list-style-position: inside;
  }`, "",{"version":3,"sources":["webpack://./src/components/pages/PrivacyPolicy.module.scss"],"names":[],"mappings":"AAAA;EACE,6CAA6C;AAK/C;AAHE;IACE,2BAA2B;EAC7B","sourcesContent":[".container {\n  background-color: var(--background-secondary);\n\n  ul {\n    list-style-position: inside;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `nightbot-PrivacyPolicy-module__container-DskCJ`
};
export default ___CSS_LOADER_EXPORT___;
