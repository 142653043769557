// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-GiveawayPanel-module__panel-CI7OT {
  overflow: hidden;
  padding: 0px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.nightbot-GiveawayPanel-module__settingsContainer-hjeI7 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  padding: 24px 24px 0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: scroll;
  background-color: var(--background-primary);
}`, "",{"version":3,"sources":["webpack://./src/components/giveaways/GiveawayPanel.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,OAAO;EACP,SAAS;EACT,aAAa;EACb,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,kBAAkB;EAClB,2CAA2C;AAC7C","sourcesContent":[".panel {\n  overflow: hidden;\n  padding: 0px;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n}\n\n.settingsContainer {\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  z-index: 9999;\n  padding: 24px 24px 0 24px;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  overflow-y: scroll;\n  background-color: var(--background-primary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `nightbot-GiveawayPanel-module__panel-CI7OT`,
	"settingsContainer": `nightbot-GiveawayPanel-module__settingsContainer-hjeI7`
};
export default ___CSS_LOADER_EXPORT___;
