// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-AccountSettings-module__tabPanel-DpBx6 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0px;
}

.nightbot-AccountSettings-module__settingsHeader-_gSTW {
  display: flex;
  gap: 16px;
  align-items: center;
}

.nightbot-AccountSettings-module__userInfo-HW8N9 {
  display: flex;
  flex-direction: column;
}

.nightbot-AccountSettings-module__userInfo-HW8N9 .nightbot-AccountSettings-module__memberSince-n95OW {
    color: var(--text-secondary);
  }`, "",{"version":3,"sources":["webpack://./src/components/pages/AccountSettings.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;AAKxB;;AAHE;IACE,4BAA4B;EAC9B","sourcesContent":[".tabPanel {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  padding: 24px 0px;\n}\n\n.settingsHeader {\n  display: flex;\n  gap: 16px;\n  align-items: center;\n}\n\n.userInfo {\n  display: flex;\n  flex-direction: column;\n\n  .memberSince {\n    color: var(--text-secondary);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabPanel": `nightbot-AccountSettings-module__tabPanel-DpBx6`,
	"settingsHeader": `nightbot-AccountSettings-module__settingsHeader-_gSTW`,
	"userInfo": `nightbot-AccountSettings-module__userInfo-HW8N9`,
	"memberSince": `nightbot-AccountSettings-module__memberSince-n95OW`
};
export default ___CSS_LOADER_EXPORT___;
