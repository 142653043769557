// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-Regulars-module__actionsColumn-oR_12 {
  text-align: right;
  width: 1px;
}

.nightbot-Regulars-module__userHeader-x19jk,
.nightbot-Regulars-module__userColumn-DDUYR {
  max-width: 1px;
}

.nightbot-Regulars-module__userColumn-DDUYR {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nightbot-Regulars-module__createdAtColumn-xUma5 {
  white-space: nowrap;
}

.nightbot-Regulars-module__createdAtHeader-jFPIm,
.nightbot-Regulars-module__createdAtColumn-xUma5 {
  width: 1px;
}

.nightbot-Regulars-module__buttonRow-tJisJ {
  display: flex;
  gap: 24px;
}

.nightbot-Regulars-module__newRegularButton-fJrRr {
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Regulars.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,UAAU;AACZ;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;EAEE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".actionsColumn {\n  text-align: right;\n  width: 1px;\n}\n\n.userHeader,\n.userColumn {\n  max-width: 1px;\n}\n\n.userColumn {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.createdAtColumn {\n  white-space: nowrap;\n}\n\n.createdAtHeader,\n.createdAtColumn {\n  width: 1px;\n}\n\n.buttonRow {\n  display: flex;\n  gap: 24px;\n}\n\n.newRegularButton {\n  margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsColumn": `nightbot-Regulars-module__actionsColumn-oR_12`,
	"userHeader": `nightbot-Regulars-module__userHeader-x19jk`,
	"userColumn": `nightbot-Regulars-module__userColumn-DDUYR`,
	"createdAtColumn": `nightbot-Regulars-module__createdAtColumn-xUma5`,
	"createdAtHeader": `nightbot-Regulars-module__createdAtHeader-jFPIm`,
	"buttonRow": `nightbot-Regulars-module__buttonRow-tJisJ`,
	"newRegularButton": `nightbot-Regulars-module__newRegularButton-fJrRr`
};
export default ___CSS_LOADER_EXPORT___;
