// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-CommandsLayout-module__buttonRow-g54yM {
  display: flex;
  gap: 24px;
  align-items: center;
}

.nightbot-CommandsLayout-module__newCommandButton-kvdud {
  margin-left: auto;
}

@media (max-width: 992px) {

.nightbot-CommandsLayout-module__searchInput-iIz0H {
    display: none;
}
  }

@media (min-width: 992px) {

.nightbot-CommandsLayout-module__mobileSearchInput-uWLE3 {
    display: none;
}
  }`, "",{"version":3,"sources":["webpack://./src/components/layouts/CommandsLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAGE;;AADF;IAEI,aAAa;AAEjB;EADE;;AAIA;;AADF;IAEI,aAAa;AAEjB;EADE","sourcesContent":[".buttonRow {\n  display: flex;\n  gap: 24px;\n  align-items: center;\n}\n\n.newCommandButton {\n  margin-left: auto;\n}\n\n.searchInput {\n  @media (max-width: $mantine-breakpoint-md) {\n    display: none;\n  }\n}\n\n.mobileSearchInput {\n  @media (min-width: $mantine-breakpoint-md) {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonRow": `nightbot-CommandsLayout-module__buttonRow-g54yM`,
	"newCommandButton": `nightbot-CommandsLayout-module__newCommandButton-kvdud`,
	"searchInput": `nightbot-CommandsLayout-module__searchInput-iIz0H`,
	"mobileSearchInput": `nightbot-CommandsLayout-module__mobileSearchInput-uWLE3`
};
export default ___CSS_LOADER_EXPORT___;
