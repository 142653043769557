// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-OAuthApplicationSettings-module__redirectUri-nI58z {
  display: flex;
  gap: 8px;
  align-items: center;
  color: inherit;
}

.nightbot-OAuthApplicationSettings-module__invalidUri-K2vy4 {
  color: var(--color-negative);
}

.nightbot-OAuthApplicationSettings-module__actionsColumn-E6P2_ {
  width: 1px;
  text-align: right;
}

.nightbot-OAuthApplicationSettings-module__addRedirectUriFooter-F54Fr {
  display: flex;
  justify-content: space-between;
}

.nightbot-OAuthApplicationSettings-module__loaderContainer-ptLnx {
  display: grid;
  align-items: center;
  justify-items: center;
  place-items: center;
  height: 100%;
  width: 100%;
}

.nightbot-OAuthApplicationSettings-module__applicationName-PF5sj {
  width: -moz-fit-content;
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/components/account_settings/OAuthApplicationSettings.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EAAnB,qBAAmB;EAAnB,mBAAmB;EACnB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;AACpB","sourcesContent":[".redirectUri {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  color: inherit;\n}\n\n.invalidUri {\n  color: var(--color-negative);\n}\n\n.actionsColumn {\n  width: 1px;\n  text-align: right;\n}\n\n.addRedirectUriFooter {\n  display: flex;\n  justify-content: space-between;\n}\n\n.loaderContainer {\n  display: grid;\n  place-items: center;\n  height: 100%;\n  width: 100%;\n}\n\n.applicationName {\n  width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redirectUri": `nightbot-OAuthApplicationSettings-module__redirectUri-nI58z`,
	"invalidUri": `nightbot-OAuthApplicationSettings-module__invalidUri-K2vy4`,
	"actionsColumn": `nightbot-OAuthApplicationSettings-module__actionsColumn-E6P2_`,
	"addRedirectUriFooter": `nightbot-OAuthApplicationSettings-module__addRedirectUriFooter-F54Fr`,
	"loaderContainer": `nightbot-OAuthApplicationSettings-module__loaderContainer-ptLnx`,
	"applicationName": `nightbot-OAuthApplicationSettings-module__applicationName-PF5sj`
};
export default ___CSS_LOADER_EXPORT___;
