// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-SongRequests-module__videoPlayerPanel-tVpXA {
  overflow: hidden;
  padding: 0px;
  border: none;
}

@media (min-width: 768px) {

.nightbot-SongRequests-module__videoPlayerPanel-tVpXA {
    flex: 1;
}
  }

.nightbot-SongRequests-module__songRequestsTablePanel-TlJwz {
  flex: 1;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom: none;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/SongRequests.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,YAAY;AAKd;;AAHE;;AALF;IAMI,OAAO;AAEX;EADE;;AAGF;EACE,OAAO;EACP,+BAA+B;EAC/B,8BAA8B;EAC9B,mBAAmB;AACrB","sourcesContent":[".videoPlayerPanel {\n  overflow: hidden;\n  padding: 0px;\n  border: none;\n\n  @media (min-width: $mantine-breakpoint-sm) {\n    flex: 1;\n  }\n}\n\n.songRequestsTablePanel {\n  flex: 1;\n  border-bottom-right-radius: 0px;\n  border-bottom-left-radius: 0px;\n  border-bottom: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoPlayerPanel": `nightbot-SongRequests-module__videoPlayerPanel-tVpXA`,
	"songRequestsTablePanel": `nightbot-SongRequests-module__songRequestsTablePanel-TlJwz`
};
export default ___CSS_LOADER_EXPORT___;
