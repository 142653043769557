// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-tabs-module__tab-_zL9s {
  padding: 12px 20px;
}

.nightbot-tabs-module__tab-_zL9s:hover {
  background-color: var(--background-secondary);
}

.nightbot-tabs-module__tab-_zL9s:active {
  background-color: var(--background-hover);
}

.nightbot-tabs-module__list-wqGyR {
  --tab-border-color: var(--border-secondary);
}`, "",{"version":3,"sources":["webpack://./src/utils/mantine-theme/tabs.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".tab {\n  padding: 12px 20px;\n}\n\n.tab:hover {\n  background-color: var(--background-secondary);\n}\n\n.tab:active {\n  background-color: var(--background-hover);\n}\n\n.list {\n  --tab-border-color: var(--border-secondary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab": `nightbot-tabs-module__tab-_zL9s`,
	"list": `nightbot-tabs-module__list-wqGyR`
};
export default ___CSS_LOADER_EXPORT___;
