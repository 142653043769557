// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-ConnectionsPanel-module__actionsColumn-cK_7S {
  text-align: right;
  width: 1px;
}

.nightbot-ConnectionsPanel-module__applicationColumn-V9mTN {
  max-width: 1px;
}

.nightbot-ConnectionsPanel-module__applicationColumn-V9mTN .nightbot-ConnectionsPanel-module__applicationName-ZG1mV {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

.nightbot-ConnectionsPanel-module__actionsHeader-UDJYl {
  width: 1px;
}

.nightbot-ConnectionsPanel-module__applicationButton-hzwAO:active {
  border-radius: var(--mantine-radius-md);
  box-shadow: var(--mantine-shadow-outline);
}

.nightbot-ConnectionsPanel-module__newApplicationButton-RnEZG {
  width: -moz-fit-content;
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/components/account_settings/ConnectionsPanel.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,cAAc;AAOhB;;AALE;IACE,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;EACrB;;AAGF;EACE,UAAU;AACZ;;AAEA;EACE,uCAAuC;EACvC,yCAAyC;AAC3C;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;AACpB","sourcesContent":[".actionsColumn {\n  text-align: right;\n  width: 1px;\n}\n\n.applicationColumn {\n  max-width: 1px;\n\n  .applicationName {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n}\n\n.actionsHeader {\n  width: 1px;\n}\n\n.applicationButton:active {\n  border-radius: var(--mantine-radius-md);\n  box-shadow: var(--mantine-shadow-outline);\n}\n\n.newApplicationButton {\n  width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsColumn": `nightbot-ConnectionsPanel-module__actionsColumn-cK_7S`,
	"applicationColumn": `nightbot-ConnectionsPanel-module__applicationColumn-V9mTN`,
	"applicationName": `nightbot-ConnectionsPanel-module__applicationName-ZG1mV`,
	"actionsHeader": `nightbot-ConnectionsPanel-module__actionsHeader-UDJYl`,
	"applicationButton": `nightbot-ConnectionsPanel-module__applicationButton-hzwAO`,
	"newApplicationButton": `nightbot-ConnectionsPanel-module__newApplicationButton-RnEZG`
};
export default ___CSS_LOADER_EXPORT___;
