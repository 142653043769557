// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-SettingsPanel-module__settingPanel-Y08JP {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
  gap: 16px;
  align-items: center;
}

.nightbot-SettingsPanel-module__settingPanel-Y08JP .nightbot-SettingsPanel-module__icon-LLpp8 {
    background-color: var(--background-hover);
    padding: 12px;
    border-radius: var(--mantine-radius-md);
  }

.nightbot-SettingsPanel-module__settingPanel-Y08JP .nightbot-SettingsPanel-module__content-vqZHa {
    display: flex;
    flex-direction: column;
  }

.nightbot-SettingsPanel-module__settingPanel-Y08JP .nightbot-SettingsPanel-module__description-LJcCC {
    color: var(--text-secondary);
    --text-color: var(--text-secondary);
  }

.nightbot-SettingsPanel-module__settingPanel-Y08JP .nightbot-SettingsPanel-module__afterElementContainer-VE0Mf {
    margin-left: auto;
  }

.nightbot-SettingsPanel-module__error-JoF_o {
  color: var(--text-error);
  --text-color: var(--text-error);
}`, "",{"version":3,"sources":["webpack://./src/components/SettingsPanel.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,aAAa;EACb,SAAS;EACT,mBAAmB;AAqBrB;;AAnBE;IACE,yCAAyC;IACzC,aAAa;IACb,uCAAuC;EACzC;;AAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;AAEA;IACE,4BAA4B;IAC5B,mCAAmC;EACrC;;AAEA;IACE,iBAAiB;EACnB;;AAGF;EACE,wBAAwB;EACxB,+BAA+B;AACjC","sourcesContent":[".settingPanel {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  padding: 16px;\n  gap: 16px;\n  align-items: center;\n\n  .icon {\n    background-color: var(--background-hover);\n    padding: 12px;\n    border-radius: var(--mantine-radius-md);\n  }\n\n  .content {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .description {\n    color: var(--text-secondary);\n    --text-color: var(--text-secondary);\n  }\n\n  .afterElementContainer {\n    margin-left: auto;\n  }\n}\n\n.error {\n  color: var(--text-error);\n  --text-color: var(--text-error);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingPanel": `nightbot-SettingsPanel-module__settingPanel-Y08JP`,
	"icon": `nightbot-SettingsPanel-module__icon-LLpp8`,
	"content": `nightbot-SettingsPanel-module__content-vqZHa`,
	"description": `nightbot-SettingsPanel-module__description-LJcCC`,
	"afterElementContainer": `nightbot-SettingsPanel-module__afterElementContainer-VE0Mf`,
	"error": `nightbot-SettingsPanel-module__error-JoF_o`
};
export default ___CSS_LOADER_EXPORT___;
