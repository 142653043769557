// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-DashboardLoader-module__loader-uKHNk {
    /* TODO: update once DashboardLayout.tsx is updated */
    display: grid !important;
    align-items: center;
    justify-items: center;
    place-items: center;
    height: 100%;
    width: 100%;
    flex: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/DashboardLoader.module.scss"],"names":[],"mappings":"AAAA;IACI,qDAAqD;IACrD,wBAAwB;IACxB,mBAAmB;IAAnB,qBAAmB;IAAnB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,OAAO;AACX","sourcesContent":[".loader {\n    /* TODO: update once DashboardLayout.tsx is updated */\n    display: grid !important;\n    place-items: center;\n    height: 100%;\n    width: 100%;\n    flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `nightbot-DashboardLoader-module__loader-uKHNk`
};
export default ___CSS_LOADER_EXPORT___;
