// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-OAuth2-module__panel-eE7Eg {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 0px;
  max-width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/OAuth2.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,QAAQ;EACR,gBAAgB;AAClB","sourcesContent":[".panel {\n  display: flex;\n  flex-direction: column;\n  padding: 0px;\n  gap: 0px;\n  max-width: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `nightbot-OAuth2-module__panel-eE7Eg`
};
export default ___CSS_LOADER_EXPORT___;
