// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-GiveawayKeywordStep-module__textInput-ViB_j, .nightbot-GiveawayKeywordStep-module__afterElementContainer-PEvYQ {
  width: 100%;
}

.nightbot-GiveawayKeywordStep-module__subHeading-QOiSO {
  color: var(--text-secondary);
}

.nightbot-GiveawayKeywordStep-module__settingsPanel-nR8s2 {
  background-color: var(--background-hover);
  border-color: var(--border-secondary);
}`, "",{"version":3,"sources":["webpack://./src/components/giveaways/GiveawayKeywordStep.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,yCAAyC;EACzC,qCAAqC;AACvC","sourcesContent":[".textInput, .afterElementContainer {\n  width: 100%;\n}\n\n.subHeading {\n  color: var(--text-secondary);\n}\n\n.settingsPanel {\n  background-color: var(--background-hover);\n  border-color: var(--border-secondary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textInput": `nightbot-GiveawayKeywordStep-module__textInput-ViB_j`,
	"afterElementContainer": `nightbot-GiveawayKeywordStep-module__afterElementContainer-PEvYQ`,
	"subHeading": `nightbot-GiveawayKeywordStep-module__subHeading-QOiSO`,
	"settingsPanel": `nightbot-GiveawayKeywordStep-module__settingsPanel-nR8s2`
};
export default ___CSS_LOADER_EXPORT___;
