// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-DashboardNavbar-module__sideBar-AGgM7 {
  height: 100%;
}

.nightbot-DashboardNavbar-module__drawerBody-Z7zzB {
  /* TODO: Remove !important when DashboardNavbar is updated */
  padding: 0px !important;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/DashboardNavbar.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,4DAA4D;EAC5D,uBAAuB;EACvB,YAAY;AACd","sourcesContent":[".sideBar {\n  height: 100%;\n}\n\n.drawerBody {\n  /* TODO: Remove !important when DashboardNavbar is updated */\n  padding: 0px !important;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideBar": `nightbot-DashboardNavbar-module__sideBar-AGgM7`,
	"drawerBody": `nightbot-DashboardNavbar-module__drawerBody-Z7zzB`
};
export default ___CSS_LOADER_EXPORT___;
