// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-GiveawayActiveStep-module__numberInput-r_V4e {
  width: 100%;
}

.nightbot-GiveawayActiveStep-module__settingsPanel-tnM2A {
  background-color: var(--background-hover);
  border-color: var(--border-secondary);
}

.nightbot-GiveawayActiveStep-module__subHeading-nknuJ {
  color: var(--text-secondary);
}`, "",{"version":3,"sources":["webpack://./src/components/giveaways/GiveawayActiveStep.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,yCAAyC;EACzC,qCAAqC;AACvC;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".numberInput {\n  width: 100%;\n}\n\n.settingsPanel {\n  background-color: var(--background-hover);\n  border-color: var(--border-secondary);\n}\n\n.subHeading {\n  color: var(--text-secondary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"numberInput": `nightbot-GiveawayActiveStep-module__numberInput-r_V4e`,
	"settingsPanel": `nightbot-GiveawayActiveStep-module__settingsPanel-tnM2A`,
	"subHeading": `nightbot-GiveawayActiveStep-module__subHeading-nknuJ`
};
export default ___CSS_LOADER_EXPORT___;
