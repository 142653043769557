// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-EmptyState-module__emptyState-d6wL9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  max-width: 400px;
  text-align: center;
}

.nightbot-EmptyState-module__description-Ocq3R {
  color: var(--text-secondary);
  --text-color: var(--text-secondary);
}

.nightbot-EmptyState-module__icon-wubsv {
  padding: 16px;
  border-width: 1px;
  border-color: var(--border-secondary);
  background-color: var(--background-hover);
  border-radius: var(--mantine-radius-lg);
  margin-bottom: 24px;
  display: grid;
  align-items: center;
  justify-items: center;
  align-items: center;
  justify-items: center;
  place-items: center;
}

.nightbot-EmptyState-module__icon-wubsv[data-mantine-color-scheme='light'] {
    border-color: var(--border-secondary);
}

.nightbot-EmptyState-module__buttons-ezXJz {
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/EmptyState.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,qCAAqC;EACrC,yCAAyC;EACzC,uCAAuC;EACvC,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EAAnB,qBAAmB;EAAnB,mBAAmB;EAAnB,qBAAmB;EAAnB,mBAAmB;AAKrB;;AAHE;IACE;AACF;;AAGF;EACE,gBAAgB;AAClB","sourcesContent":[".emptyState {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 32px;\n  max-width: 400px;\n  text-align: center;\n}\n\n.description {\n  color: var(--text-secondary);\n  --text-color: var(--text-secondary);\n}\n\n.icon {\n  padding: 16px;\n  border-width: 1px;\n  border-color: var(--border-secondary);\n  background-color: var(--background-hover);\n  border-radius: var(--mantine-radius-lg);\n  margin-bottom: 24px;\n  display: grid;\n  place-items: center;\n\n  @mixin light-root {\n    border-color: var(--border-secondary);\n  }\n}\n\n.buttons {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyState": `nightbot-EmptyState-module__emptyState-d6wL9`,
	"description": `nightbot-EmptyState-module__description-Ocq3R`,
	"icon": `nightbot-EmptyState-module__icon-wubsv`,
	"buttons": `nightbot-EmptyState-module__buttons-ezXJz`
};
export default ___CSS_LOADER_EXPORT___;
