// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-ChatWindow-module__hidden-j_R9S {
  visibility: hidden;
}

.nightbot-ChatWindow-module__panel-Z1pIV {
  display: grid;
  align-items: center;
  justify-items: center;
  place-items: center;
  padding: 0px;
  overflow: hidden;
}

.nightbot-ChatWindow-module__loader-KFo5T {
  position: absolute;
}

.nightbot-ChatWindow-module__iframe-XU_K6 {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/giveaways/ChatWindow.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EAAnB,qBAAmB;EAAnB,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".hidden {\n  visibility: hidden;\n}\n\n.panel {\n  display: grid;\n  place-items: center;\n  padding: 0px;\n  overflow: hidden;\n}\n\n.loader {\n  position: absolute;\n}\n\n.iframe {\n  width: 100%;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": `nightbot-ChatWindow-module__hidden-j_R9S`,
	"panel": `nightbot-ChatWindow-module__panel-Z1pIV`,
	"loader": `nightbot-ChatWindow-module__loader-KFo5T`,
	"iframe": `nightbot-ChatWindow-module__iframe-XU_K6`
};
export default ___CSS_LOADER_EXPORT___;
