// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-Giveaway-module__chatWindow-Fh5aZ {
  flex: 1;
}

@media (max-width: 768px) {

.nightbot-Giveaway-module__chatWindow-Fh5aZ {
    display: none;
}
  }

.nightbot-Giveaway-module__giveawayPanel-YnHI6 {
  flex: 1;
}

.nightbot-Giveaway-module__giveawayContainer-RflYl {
  flex: 1;
  display: flex;
  gap: 24px;
  max-height: 1000px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Giveaway.module.scss"],"names":[],"mappings":"AAAA;EACE,OAAO;AAKT;;AAHE;;AAHF;IAII,aAAa;AAEjB;EADE;;AAGF;EACE,OAAO;AACT;;AAEA;EACE,OAAO;EACP,aAAa;EACb,SAAS;EACT,kBAAkB;AACpB","sourcesContent":[".chatWindow {\n  flex: 1;\n\n  @media (max-width: $mantine-breakpoint-sm) {\n    display: none;\n  }\n}\n\n.giveawayPanel {\n  flex: 1;\n}\n\n.giveawayContainer {\n  flex: 1;\n  display: flex;\n  gap: 24px;\n  max-height: 1000px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatWindow": `nightbot-Giveaway-module__chatWindow-Fh5aZ`,
	"giveawayPanel": `nightbot-Giveaway-module__giveawayPanel-YnHI6`,
	"giveawayContainer": `nightbot-Giveaway-module__giveawayContainer-RflYl`
};
export default ___CSS_LOADER_EXPORT___;
