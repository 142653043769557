// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-GiveawayUserGuessList-module__container-vfpFS {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.nightbot-GiveawayUserGuessList-module__header-ZFwPd {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  background-color: var(--background-primary);
}

.nightbot-GiveawayUserGuessList-module__guessCount-cVcP6 {
  color: var(--text-secondary);
  --text-color: var(--text-secondary);
}

.nightbot-GiveawayUserGuessList-module__entry-EePdd {
  display: flex;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid var(--border-primary);
  align-items: center;
  background-color: var(--background-primary);
}

.nightbot-GiveawayUserGuessList-module__entries-zLzSv {
  flex: 1;
  overflow-y: hidden;
}

.nightbot-GiveawayUserGuessList-module__emptyState-DPCq7 {
  flex-grow: 1;
  display: grid;
  align-items: center;
  justify-items: center;
  place-items: center;
  color: var(--text-secondary);
}

.nightbot-GiveawayUserGuessList-module__footer-EY40i {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
  align-items: center;
  background-color: var(--background-primary);
}

.nightbot-GiveawayUserGuessList-module__footerText-nY3nr {
  --text-color: var(--text-secondary);
  color: var(--text-secondary);
}

.nightbot-GiveawayUserGuessList-module__badge-mT1lR {
  margin-left: auto;
}

.nightbot-GiveawayUserGuessList-module__guessRange-LNCLT {
  color: var(--text-tertiary);
  --text-color: var(--text-tertiary);
}`, "",{"version":3,"sources":["webpack://./src/components/giveaways/GiveawayUserGuessList.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,OAAO;AACT;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,2CAA2C;AAC7C;;AAEA;EACE,4BAA4B;EAC5B,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,SAAS;EACT,aAAa;EACb,8CAA8C;EAC9C,mBAAmB;EACnB,2CAA2C;AAC7C;;AAEA;EACE,OAAO;EACP,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EAAnB,qBAAmB;EAAnB,mBAAmB;EACnB,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,2CAA2C;AAC7C;;AAEA;EACE,mCAAmC;EACnC,4BAA4B;AAC9B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,2BAA2B;EAC3B,kCAAkC;AACpC","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  padding: 16px;\n  align-items: center;\n  background-color: var(--background-primary);\n}\n\n.guessCount {\n  color: var(--text-secondary);\n  --text-color: var(--text-secondary);\n}\n\n.entry {\n  display: flex;\n  gap: 16px;\n  padding: 16px;\n  border-bottom: 1px solid var(--border-primary);\n  align-items: center;\n  background-color: var(--background-primary);\n}\n\n.entries {\n  flex: 1;\n  overflow-y: hidden;\n}\n\n.emptyState {\n  flex-grow: 1;\n  display: grid;\n  place-items: center;\n  color: var(--text-secondary);\n}\n\n.footer {\n  display: flex;\n  justify-content: space-between;\n  padding: 16px;\n  gap: 16px;\n  align-items: center;\n  background-color: var(--background-primary);\n}\n\n.footerText {\n  --text-color: var(--text-secondary);\n  color: var(--text-secondary);\n}\n\n.badge {\n  margin-left: auto;\n}\n\n.guessRange {\n  color: var(--text-tertiary);\n  --text-color: var(--text-tertiary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `nightbot-GiveawayUserGuessList-module__container-vfpFS`,
	"header": `nightbot-GiveawayUserGuessList-module__header-ZFwPd`,
	"guessCount": `nightbot-GiveawayUserGuessList-module__guessCount-cVcP6`,
	"entry": `nightbot-GiveawayUserGuessList-module__entry-EePdd`,
	"entries": `nightbot-GiveawayUserGuessList-module__entries-zLzSv`,
	"emptyState": `nightbot-GiveawayUserGuessList-module__emptyState-DPCq7`,
	"footer": `nightbot-GiveawayUserGuessList-module__footer-EY40i`,
	"footerText": `nightbot-GiveawayUserGuessList-module__footerText-nY3nr`,
	"badge": `nightbot-GiveawayUserGuessList-module__badge-mT1lR`,
	"guessRange": `nightbot-GiveawayUserGuessList-module__guessRange-LNCLT`
};
export default ___CSS_LOADER_EXPORT___;
