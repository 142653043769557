// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-LegalLayout-module__legalLayout-UKG3v {
  width: 100%;
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
  flex: 1;
}

.nightbot-LegalLayout-module__navBar-I2Jhg {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--background-secondary);
}

.nightbot-LegalLayout-module__footer-R5ycl {
  /* TODO: remove important once footer is updated to mantine */
  background-color: var(--background-secondary) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/LegalLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,2CAA2C;EAC3C,aAAa;EACb,sBAAsB;EACtB,OAAO;AACT;;AAEA;EACE,gBAAgB;EAChB,MAAM;EACN,WAAW;EACX,6CAA6C;AAC/C;;AAEA;EACE,6DAA6D;EAC7D,wDAAwD;AAC1D","sourcesContent":[".legalLayout {\n  width: 100%;\n  background-color: var(--background-primary);\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n.navBar {\n  position: sticky;\n  top: 0;\n  z-index: 10;\n  background-color: var(--background-secondary);\n}\n\n.footer {\n  /* TODO: remove important once footer is updated to mantine */\n  background-color: var(--background-secondary) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legalLayout": `nightbot-LegalLayout-module__legalLayout-UKG3v`,
	"navBar": `nightbot-LegalLayout-module__navBar-I2Jhg`,
	"footer": `nightbot-LegalLayout-module__footer-R5ycl`
};
export default ___CSS_LOADER_EXPORT___;
