// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-menu-module__dropdown-mWs34 {
  padding: 4px;
  background-color: var(--background-primary);
  border-color: var(--border-primary);
  --menu-item-hover: var(--background-secondary);
  --popover-border-color: var(--border-primary);
}
.nightbot-menu-module__dropdown-mWs34 .nightbot-menu-module__label-ig3UJ,.nightbot-menu-module__dropdown-mWs34 .nightbot-menu-module__item-TDMp5 {
    padding: 4px 12px;
    gap: 4px;
  }
.nightbot-menu-module__dropdown-mWs34 .nightbot-menu-module__item-TDMp5:hover {
    background-color: var(--background-hover);
  }
.nightbot-menu-module__dropdown-mWs34 .nightbot-menu-module__item-TDMp5[data-variant='active'] {
      background-color: var(--background-accent);
      color: var(--text-active);
    }
`, "",{"version":3,"sources":["webpack://./src/utils/mantine-theme/menu.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,2CAA2C;EAC3C,mCAAmC;EACnC,8CAA8C;EAC9C,6CAA6C;AAkB/C;AAhBE;IAEE,iBAAiB;IACjB,QAAQ;EACV;AAEA;IACE,yCAAyC;EAC3C;AAGE;MACE,0CAA0C;MAC1C,yBAAyB;IAC3B","sourcesContent":[".dropdown {\n  padding: 4px;\n  background-color: var(--background-primary);\n  border-color: var(--border-primary);\n  --menu-item-hover: var(--background-secondary);\n  --popover-border-color: var(--border-primary);\n\n  .label,\n  .item {\n    padding: 4px 12px;\n    gap: 4px;\n  }\n\n  .item:hover {\n    background-color: var(--background-hover);\n  }\n\n  .item {\n    &[data-variant='active'] {\n      background-color: var(--background-accent);\n      color: var(--text-active);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `nightbot-menu-module__dropdown-mWs34`,
	"label": `nightbot-menu-module__label-ig3UJ`,
	"item": `nightbot-menu-module__item-TDMp5`
};
export default ___CSS_LOADER_EXPORT___;
