// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../Table.module.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-SongRequestTable-module__songHeading-O7FQE {
  max-width: 1px;
}

.nightbot-SongRequestTable-module__playlistSeperator-SM55I {
  cursor: pointer;
}

.nightbot-SongRequestTable-module__playlistSeperator-SM55I:hover {
    -webkit-text-decoration: underline;
    text-decoration: underline;
    text-decoration-color: currentColor;
  }

.nightbot-SongRequestTable-module__panel-Iy4EP {
}

.nightbot-SongRequestTable-module__table-Bvlpo {
}

.nightbot-SongRequestTable-module__emptyPanel-hrDq7 {
  display: grid;
  align-items: center;
  justify-items: center;
  place-items: center;
  padding: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/song_requests/SongRequestTable.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AAMjB;;AAJE;IACE,kCAA0B;IAA1B,0BAA0B;IAC1B,mCAAmC;EACrC;;AAGF;AAEA;;AAEA;AAEA;;AAEA;EACE,aAAa;EACb,mBAAmB;EAAnB,qBAAmB;EAAnB,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".songHeading {\n  max-width: 1px;\n}\n\n.playlistSeperator {\n  cursor: pointer;\n\n  &:hover {\n    text-decoration: underline;\n    text-decoration-color: currentColor;\n  }\n}\n\n.panel {\n  composes: panel from '../Table.module.scss';\n}\n\n.table {\n  composes: table from '../Table.module.scss';\n}\n\n.emptyPanel {\n  display: grid;\n  place-items: center;\n  padding: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"songHeading": `nightbot-SongRequestTable-module__songHeading-O7FQE`,
	"playlistSeperator": `nightbot-SongRequestTable-module__playlistSeperator-SM55I`,
	"panel": `nightbot-SongRequestTable-module__panel-Iy4EP ${___CSS_LOADER_ICSS_IMPORT_0___.locals["panel"]}`,
	"table": `nightbot-SongRequestTable-module__table-Bvlpo ${___CSS_LOADER_ICSS_IMPORT_0___.locals["table"]}`,
	"emptyPanel": `nightbot-SongRequestTable-module__emptyPanel-hrDq7`
};
export default ___CSS_LOADER_EXPORT___;
