'use strict';
var prefix = 'fas';
var iconName = 'message-slash';
var width = 640;
var height = 512;
var aliases = [
    'comment-alt-slash'
];
var unicode = 'f4a9';
var svgPathData = 'M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7l-82.3-64.5C565.1 393 576 373.8 576 352V64c0-35.3-28.7-64-64-64H128C104.4 0 83.8 12.7 72.7 31.7L38.8 5.1zM64 352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L373.3 416h32.8L64 146.4V352z';
export var faMessageSlash = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        aliases,
        unicode,
        svgPathData
    ]
};
