// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-Notice-module__logo-H4yjN {
  align-self: center;
  padding: 16px;
}

.nightbot-Notice-module__panel-wdRL9 {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: 0px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Notice.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,YAAY;EACZ,WAAW;AACb","sourcesContent":[".logo {\n  align-self: center;\n  padding: 16px;\n}\n\n.panel {\n  display: flex;\n  flex-direction: column;\n  max-width: 400px;\n  padding: 0px;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `nightbot-Notice-module__logo-H4yjN`,
	"panel": `nightbot-Notice-module__panel-wdRL9`
};
export default ___CSS_LOADER_EXPORT___;
