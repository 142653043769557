'use strict';
var prefix = 'fas';
var iconName = 'message-text';
var width = 512;
var height = 512;
var aliases = [
    'comment-alt-text'
];
var unicode = 'e1e6';
var svgPathData = 'M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zm168 48c-13.3 0-24 10.7-24 24s10.7 24 24 24h64V296c0 13.3 10.7 24 24 24s24-10.7 24-24V160h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H256 168z';
export var faMessageText = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        aliases,
        unicode,
        svgPathData
    ]
};
