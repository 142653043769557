// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-TermsOfService-module__container-fOoD6 {
  background-color: var(--background-secondary);
}`, "",{"version":3,"sources":["webpack://./src/components/pages/TermsOfService.module.scss"],"names":[],"mappings":"AAAA;EACE,6CAA6C;AAC/C","sourcesContent":[".container {\n  background-color: var(--background-secondary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `nightbot-TermsOfService-module__container-fOoD6`
};
export default ___CSS_LOADER_EXPORT___;
