// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-TopCommand-module__pagination-OKOSj {
  padding: 8px;
  gap: 8px;
  display: flex;
  justify-content: right;
}

.nightbot-TopCommand-module__hidden-D3LpW {
  visibility: hidden;
}

.nightbot-TopCommand-module__commandMessageColumn-XuWag {
  text-align: right;
}

.nightbot-TopCommand-module__panel-i4byV {
  display: grid;
  align-items: center;
  justify-items: center;
  place-items: center;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/TopCommand.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,QAAQ;EACR,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EAAnB,qBAAmB;EAAnB,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".pagination {\n  padding: 8px;\n  gap: 8px;\n  display: flex;\n  justify-content: right;\n}\n\n.hidden {\n  visibility: hidden;\n}\n\n.commandMessageColumn {\n  text-align: right;\n}\n\n.panel {\n  display: grid;\n  place-items: center;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `nightbot-TopCommand-module__pagination-OKOSj`,
	"hidden": `nightbot-TopCommand-module__hidden-D3LpW`,
	"commandMessageColumn": `nightbot-TopCommand-module__commandMessageColumn-XuWag`,
	"panel": `nightbot-TopCommand-module__panel-i4byV`
};
export default ___CSS_LOADER_EXPORT___;
