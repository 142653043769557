// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-GiveawayWinnerMessageSetting-module__settingsPanel-w10kV {
  background-color: var(--background-hover);
  border-color: var(--border-secondary);
}`, "",{"version":3,"sources":["webpack://./src/components/giveaways/GiveawayWinnerMessageSetting.module.scss"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,qCAAqC;AACvC","sourcesContent":[".settingsPanel {\n  background-color: var(--background-hover);\n  border-color: var(--border-secondary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsPanel": `nightbot-GiveawayWinnerMessageSetting-module__settingsPanel-w10kV`
};
export default ___CSS_LOADER_EXPORT___;
