// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-SpamProtection-module__panel-HoSdy {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0px;
}

.nightbot-SpamProtection-module__panelContent-f6beO {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: flex;
  gap: 24px;
  padding: 24px;
  align-items: flex-start;
}

.nightbot-SpamProtection-module__icon-LSsFi {
  padding: 12px;
  margin-bottom: 4px;
  background-color: var(--background-hover);
  border-radius: var(--mantine-radius-lg);
}

.nightbot-SpamProtection-module__filterInfo-enDr0 {
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
}

.nightbot-SpamProtection-module__filterInfo-enDr0 .nightbot-SpamProtection-module__description-1LYSR {
    color: var(--text-secondary);
    --text-color: var(--text-secondary);
  }

.nightbot-SpamProtection-module__panelActions-w4464 {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  gap: 24px;
  width: 100%;
}

.nightbot-SpamProtection-module__panelActions-w4464 .nightbot-SpamProtection-module__enabledText-w4jPI {
    color: var(--text-secondary);
    --text-color: var(--text-secondary);
    margin-right: auto;
  }

.nightbot-SpamProtection-module__panelActions-w4464 .nightbot-SpamProtection-module__actionIcons-eRklG {
    display: flex;
    gap: 16px;
  }`, "",{"version":3,"sources":["webpack://./src/components/pages/SpamProtection.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,YAAY;AACd;;AAEA;EACE,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;EACjB,aAAa;EACb,SAAS;EACT,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,yCAAyC;EACzC,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,sBAAsB;EACtB,uBAAuB;AAMzB;;AAJE;IACE,4BAA4B;IAC5B,mCAAmC;EACrC;;AAGF;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;EACT,WAAW;AAYb;;AAVE;IACE,4BAA4B;IAC5B,mCAAmC;IACnC,kBAAkB;EACpB;;AAEA;IACE,aAAa;IACb,SAAS;EACX","sourcesContent":[".panel {\n  display: flex;\n  flex-direction: column;\n  gap: 0px;\n  padding: 0px;\n}\n\n.panelContent {\n  user-select: none;\n  display: flex;\n  gap: 24px;\n  padding: 24px;\n  align-items: flex-start;\n}\n\n.icon {\n  padding: 12px;\n  margin-bottom: 4px;\n  background-color: var(--background-hover);\n  border-radius: var(--mantine-radius-lg);\n}\n\n.filterInfo {\n  display: flex;\n  gap: 8px;\n  flex-direction: column;\n  align-items: flex-start;\n\n  .description {\n    color: var(--text-secondary);\n    --text-color: var(--text-secondary);\n  }\n}\n\n.panelActions {\n  display: flex;\n  align-items: center;\n  padding: 16px 24px;\n  gap: 24px;\n  width: 100%;\n\n  .enabledText {\n    color: var(--text-secondary);\n    --text-color: var(--text-secondary);\n    margin-right: auto;\n  }\n\n  .actionIcons {\n    display: flex;\n    gap: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `nightbot-SpamProtection-module__panel-HoSdy`,
	"panelContent": `nightbot-SpamProtection-module__panelContent-f6beO`,
	"icon": `nightbot-SpamProtection-module__icon-LSsFi`,
	"filterInfo": `nightbot-SpamProtection-module__filterInfo-enDr0`,
	"description": `nightbot-SpamProtection-module__description-1LYSR`,
	"panelActions": `nightbot-SpamProtection-module__panelActions-w4464`,
	"enabledText": `nightbot-SpamProtection-module__enabledText-w4jPI`,
	"actionIcons": `nightbot-SpamProtection-module__actionIcons-eRklG`
};
export default ___CSS_LOADER_EXPORT___;
