// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-Integrations-module__panel-JYOUI {
  max-width: 360px;
  padding: 0px;
}

.nightbot-Integrations-module__discordIcon-FIq8g {
  background-color: var(--color-discord-blurple);
  color: var(--mantine-color-blue-0);
}

.nightbot-Integrations-module__icon-kj5bE {
  padding: 8px;
  border-radius: var(--mantine-radius-lg);
}

.nightbot-Integrations-module__buttonGroup-CjIzu {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  gap: 24px;
}

.nightbot-Integrations-module__stack-tI8L2 {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
}

.nightbot-Integrations-module__header-mW_OS {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.nightbot-Integrations-module__description-FbG51 {
  color: var(--text-secondary);
  --text-color: var(--text-secondary);
}

.nightbot-Integrations-module__disconnectButton-ck76f, .nightbot-Integrations-module__editButton-YuHVI, .nightbot-Integrations-module__connectButton-oA5S5 {
  flex: 1;
}

.nightbot-Integrations-module__disconnectButtonLabel-ojHKX {
  color: var(--color-negative);
  --button-color: var(--color-negative);
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Integrations.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,8CAA8C;EAC9C,kCAAkC;AACpC;;AAEA;EACE,YAAY;EACZ,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,uBAAuB;AACzB;;AAEA;EACE,4BAA4B;EAC5B,mCAAmC;AACrC;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,4BAA4B;EAC5B,qCAAqC;AACvC","sourcesContent":[".panel {\n  max-width: 360px;\n  padding: 0px;\n}\n\n.discordIcon {\n  background-color: var(--color-discord-blurple);\n  color: var(--mantine-color-blue-0);\n}\n\n.icon {\n  padding: 8px;\n  border-radius: var(--mantine-radius-lg);\n}\n\n.buttonGroup {\n  display: flex;\n  justify-content: space-between;\n  padding: 24px;\n  gap: 24px;\n}\n\n.stack {\n  display: flex;\n  flex-direction: column;\n  padding: 24px;\n  gap: 16px;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n}\n\n.description {\n  color: var(--text-secondary);\n  --text-color: var(--text-secondary);\n}\n\n.disconnectButton, .editButton, .connectButton {\n  flex: 1;\n}\n\n.disconnectButtonLabel {\n  color: var(--color-negative);\n  --button-color: var(--color-negative);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `nightbot-Integrations-module__panel-JYOUI`,
	"discordIcon": `nightbot-Integrations-module__discordIcon-FIq8g`,
	"icon": `nightbot-Integrations-module__icon-kj5bE`,
	"buttonGroup": `nightbot-Integrations-module__buttonGroup-CjIzu`,
	"stack": `nightbot-Integrations-module__stack-tI8L2`,
	"header": `nightbot-Integrations-module__header-mW_OS`,
	"description": `nightbot-Integrations-module__description-FbG51`,
	"disconnectButton": `nightbot-Integrations-module__disconnectButton-ck76f`,
	"editButton": `nightbot-Integrations-module__editButton-YuHVI`,
	"connectButton": `nightbot-Integrations-module__connectButton-oA5S5`,
	"disconnectButtonLabel": `nightbot-Integrations-module__disconnectButtonLabel-ojHKX`
};
export default ___CSS_LOADER_EXPORT___;
