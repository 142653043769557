// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-GiveawayEligibilityStep-module__root-yJir1 {
  position: relative;
  padding: 16px;
  transition: 100ms ease-in-out;
  transition-property: border-color, background-color, color;
  display: flex;
  background-color: var(--background-hover);
  border-color: var(--border-secondary);
  gap: 16px;
  align-items: center;
}

.nightbot-GiveawayEligibilityStep-module__root-yJir1[data-checked] {
    border-color: var(--background-accent-secondary);
    background-color: var(--background-accent);
    color: var(--text-active);
  }

.nightbot-GiveawayEligibilityStep-module__root-yJir1:active {
  box-shadow: var(--mantine-shadow-outline);
}

.nightbot-GiveawayEligibilityStep-module__grid-oieUI {
  gap: 16px;
}

.nightbot-GiveawayEligibilityStep-module__error-JROYs {
  justify-content: right;
}

.nightbot-GiveawayEligibilityStep-module__subHeading-sLKhh {
  color: var(--text-secondary);
}`, "",{"version":3,"sources":["webpack://./src/components/giveaways/GiveawayEligibilityStep.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,6BAA6B;EAC7B,0DAA0D;EAC1D,aAAa;EACb,yCAAyC;EACzC,qCAAqC;EACrC,SAAS;EACT,mBAAmB;AAOrB;;AALE;IACE,gDAAgD;IAChD,0CAA0C;IAC1C,yBAAyB;EAC3B;;AAGF;EACE,yCAAyC;AAC3C;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".root {\n  position: relative;\n  padding: 16px;\n  transition: 100ms ease-in-out;\n  transition-property: border-color, background-color, color;\n  display: flex;\n  background-color: var(--background-hover);\n  border-color: var(--border-secondary);\n  gap: 16px;\n  align-items: center;\n\n  &[data-checked] {\n    border-color: var(--background-accent-secondary);\n    background-color: var(--background-accent);\n    color: var(--text-active);\n  }\n}\n\n.root:active {\n  box-shadow: var(--mantine-shadow-outline);\n}\n\n.grid {\n  gap: 16px;\n}\n\n.error {\n  justify-content: right;\n}\n\n.subHeading {\n  color: var(--text-secondary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `nightbot-GiveawayEligibilityStep-module__root-yJir1`,
	"grid": `nightbot-GiveawayEligibilityStep-module__grid-oieUI`,
	"error": `nightbot-GiveawayEligibilityStep-module__error-JROYs`,
	"subHeading": `nightbot-GiveawayEligibilityStep-module__subHeading-sLKhh`
};
export default ___CSS_LOADER_EXPORT___;
